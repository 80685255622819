"use client";

import { Box, Flex, Grid, Skeleton } from "@radix-ui/themes";
import React from "react";

function DirectoryContentSkeleton() {
  return (
    <Flex direction={"column"} width={"100%"}>
      <Flex direction={"column"} align={"center"} pt={"9"}>
        {/* Directory title + subtitle */}
        <Skeleton
          height={"35px"}
          width={{ initial: "50%", md: "160px" }}
          mb={"7"}
        />
        <Skeleton
          height={"15px"}
          width={{ initial: "90%", md: "400px" }}
          maxWidth={"100%"}
          mb={"4"}
        />
        <Skeleton
          height={"15px"}
          width={{ initial: "70%", md: "200px" }}
          maxWidth={"100%"}
        />

        {/* Directory filters */}
        <Flex
          direction={"column"}
          px={{ initial: "3", sm: "9" }}
          mt={{ initial: "6", sm: "7" }}
          width={"100%"}
        >
          <Box width={"100%"} style={{ maxWidth: "1400px", margin: "auto" }}>
            <Skeleton
              height={{ initial: "72px", md: "64px" }}
              width={{ initial: "100%" }}
              style={{ borderRadius: "30px" }}
            />
          </Box>
        </Flex>

        {/* Directory cards */}
        <Flex
          direction={"column"}
          px={{ initial: "3", sm: "9" }}
          mt={{ initial: "6", sm: "7" }}
          width={"100%"}
        >
          <Box width={"100%"} style={{ maxWidth: "1400px", margin: "auto" }}>
            <Grid
              columns={{ initial: "1", sm: "2" }}
              gap={"5"}
              rows={{ initial: "6", sm: "3" }}
              width={"100%"}
            >
              <Skeleton
                height={{ initial: "272px", xl: "340px" }}
                width={{ initial: "100%" }}
                style={{ borderRadius: "16px" }}
              />
              <Skeleton
                height={{ initial: "272px", xl: "340px" }}
                width={{ initial: "100%" }}
                style={{ borderRadius: "16px" }}
              />
              <Skeleton
                height={{ initial: "272px", xl: "340px" }}
                width={{ initial: "100%" }}
                style={{ borderRadius: "16px" }}
              />
              <Skeleton
                height={{ initial: "272px", xl: "340px" }}
                width={{ initial: "100%" }}
                style={{ borderRadius: "16px" }}
              />
              <Skeleton
                height={{ initial: "272px", xl: "340px" }}
                width={{ initial: "100%" }}
                style={{ borderRadius: "16px" }}
              />
              <Skeleton
                height={{ initial: "272px", xl: "340px" }}
                width={{ initial: "100%" }}
                style={{ borderRadius: "16px" }}
              />
            </Grid>
          </Box>
        </Flex>

        {/* Directory client testimonials */}
        <Flex
          direction={"column"}
          align={"center"}
          gap={"5"}
          py={"9"}
          my={"8"}
          width={"100%"}
        >
          <Skeleton height={"35px"} width={{ initial: "80%" }} />
          <Skeleton height={"35px"} width={{ initial: "70%" }} />
          <Skeleton height={"35px"} width={{ initial: "50%" }} />
        </Flex>
      </Flex>

      {/* Banner */}
      <Skeleton height={{ initial: "686px" }} width={{ initial: "100%" }} />
    </Flex>
  );
}

export default DirectoryContentSkeleton;
