"use client";
import styles from "./ClientLandingHeader.module.scss";
import { HeadingV2 } from "@/design-system/components/text/HeadingV2";
import { Box, Flex } from "@radix-ui/themes";
import { useTranslations } from "next-intl";
import couples from "/public/images/couples_v1.png";
import couplesAnimated from "/public/images/couples_animated_v1.gif";
import crisisResponse from "/public/images/crisis_response_v1.png";
import crisisResponseAnimated from "/public/images/crisis_response_animated_v1.gif";
import spiritualGirl from "/public/images/spiritual_girl_v1.png";
import spiritualGirlAnimated from "/public/images/spiritual_girl_animated_v1.gif";
import getMatched from "/public/images/get_matched_v1.png";
import getMatchedAnimated from "/public/images/get_matched_animated_v1.gif";
import coaching from "/public/images/coaching_v1.png";
import coachingAnimated from "/public/images/coaching_animated_v1.gif";
import cbtWoman from "/public/images/cbt_woman.png";
import cbtWomanAnimated from "/public/images/cbt_woman.gif";
import psychoanalysis from "/public/images/psychoanalysis.png";
import psychoanalysisAnimated from "/public/images/psychoanalysis.gif";
import jungian from "/public/images/jungian_analysis.png";
import jungianAnimated from "/public/images/jungian_therapy_v1.gif";
import adhd from "/public/images/adhd_v1_still.png";
import adhdAnimated from "/public/images/adhd_v1-singleplay.gif";
import expats from "/public/images/expats_v1_still.png";
import expatsAnimated from "/public/images/expats_v1.gif";
import lgbtqi from "/public/images/LGBTQI_v18.png";
import lgbtqiAnimated from "/public/images/LGBTQI_v18.gif";

import React, { ReactElement, useEffect, useMemo, useState } from "react";
import Image, { StaticImageData } from "next/image";
import { LayoutContainerV2 } from "@/design-system/components/layout-container/LayoutContainerV2";
import { Link } from "@/navigation";
import { TextV2 } from "@/design-system/components/text/TextV2";
import { pushDataLayerEvent } from "@/analytics/analytics.datalayer";
import { NonDesktopView } from "@/design-system/responsive-helpers/NonDesktopView";
import BREAKPOINTS from "@/design-system/breakpoints";
import useScroller from "@/hooks/useScroller";
import {
  ScrollLeftButton,
  ScrollRightButton,
} from "@/design-system/components/ScrollButton/ScrollButtons";
import { directory } from "@/analytics";

function ClientLandingCuriosities() {
  const { scrollerRef, canScrollLeft, canScrollRight, scroll } = useScroller();

  const t = useTranslations("ClientLandingPage.curiosity");
  return (
    <Flex direction={"column"} align={"center"} gap={"9"} py={"9"}>
      <LayoutContainerV2>
        <Flex direction={"column"} align={"center"} gap={"5"}>
          <HeadingV2 textStyle={"Headline XL"} align={"center"} as={"h2"}>
            {t.rich("title", {
              start_here: (chunks) => (
                <span style={{ color: "var(--colorV2-dark-green" }}>
                  {chunks}
                </span>
              ),
            })}
          </HeadingV2>
          <TextV2 textStyle={"Headline S"} align={"center"}>
            {t("subtitle")}
          </TextV2>
        </Flex>
      </LayoutContainerV2>
      <Box className={styles.curiositiesWrapper}>
        <Flex
          ref={scrollerRef}
          className={styles.curiosities}
          align={"start"}
          justify={"start"}
          px={{ initial: "3", md: "9" }}
          pb={"4"}
          style={{ overflowX: "scroll", width: "100%" }}
        >
          <Curiosities />
        </Flex>
        <ScrollLeftButton
          onClick={() => scroll("left")}
          canScrollLeft={canScrollLeft}
          className={styles.overlayScrollButtonLeft}
        />
        <ScrollRightButton
          onClick={() => scroll("right")}
          canScrollRight={canScrollRight}
          className={styles.overlayScrollButtonRight}
        />
      </Box>
      <NonDesktopView>
        <Flex direction={"row"} align={"center"} justify={"center"} gap={"5"}>
          <ScrollLeftButton
            onClick={() => scroll("left")}
            canScrollLeft={canScrollLeft}
            className={styles.scrollButton}
          />
          <ScrollRightButton
            onClick={() => scroll("right")}
            canScrollRight={canScrollRight}
            className={styles.scrollButton}
          />
        </Flex>
      </NonDesktopView>
    </Flex>
  );
}

enum Curiosity {
  COACHING = "coaching",
  GET_MATCHED = "get_matched",
  PSYCHADELIC_INTEGRATION = "psychadelic_integration",
  COUPLES_THERAPY = "couples_therapy",
  CRISIS_RESPONSE = "crisis_response",
  ADHD = "adhd",
  LGBTQI = "lgbtqi",
  EXPATS = "expats",
  CBT = "cbt",
  JUNGIAN = "jungian",
  PSYCHOANALYSIS = "psychoanalysis",
}

function Curiosities() {
  const t = useTranslations("ClientLandingPage.curiosity");
  const curiosities = useMemo(() => {
    const list = [
      Curiosity.COACHING,
      Curiosity.GET_MATCHED,
      Curiosity.PSYCHADELIC_INTEGRATION,
      Curiosity.COUPLES_THERAPY,
      Curiosity.CRISIS_RESPONSE,
      Curiosity.ADHD,
      Curiosity.LGBTQI,
      Curiosity.EXPATS,
      Curiosity.CBT,
      Curiosity.JUNGIAN,
      Curiosity.PSYCHOANALYSIS,
    ];
    return list;
  }, []);
  const curiosityGenerator = (curiosity: Curiosity): ReactElement => {
    switch (curiosity) {
      case Curiosity.COACHING:
        return (
          <CuriosityCategoryLink
            slug={t("shortcuts.coaching.slug")}
            onClick={() => handleCuriosityItemClicked("coaching")}
          >
            <CuriosityImageAndText
              label={t("shortcuts.coaching.label")}
              image={coaching}
              animatedImage={coachingAnimated}
            />
          </CuriosityCategoryLink>
        );
      case Curiosity.GET_MATCHED:
        return (
          <Link
            onClick={async () => {
              directory.getMatchedClicked({ source: "filter_cta" });
              handleCuriosityItemClicked("get_matched");
              // soft conversion for ads
              pushDataLayerEvent("get_matched_clicked");
            }}
            href={"/matching/survey"}
            style={{ cursor: "pointer" }}
            className={styles.curiosityLink}
          >
            <CuriosityImageAndText
              label={t("shortcuts.get_matched")}
              image={getMatched}
              animatedImage={getMatchedAnimated}
            />
          </Link>
        );
      case Curiosity.PSYCHADELIC_INTEGRATION:
        return (
          <CuriosityCategoryLink
            slug={t("shortcuts.psychadelic_integration.slug")}
            onClick={() =>
              handleCuriosityItemClicked("psychadelic_integration")
            }
          >
            <CuriosityImageAndText
              label={t("shortcuts.psychadelic_integration.label")}
              image={spiritualGirl}
              animatedImage={spiritualGirlAnimated}
              animateAlwaysOnDesktop
            />
          </CuriosityCategoryLink>
        );
      case Curiosity.COUPLES_THERAPY:
        return (
          <CuriosityCategoryLink
            slug={t("shortcuts.couples_therapy.slug")}
            onClick={() => handleCuriosityItemClicked("couples_therapy")}
          >
            <CuriosityImageAndText
              label={t("shortcuts.couples_therapy.label")}
              image={couples}
              animatedImage={couplesAnimated}
            />
          </CuriosityCategoryLink>
        );
      case Curiosity.CRISIS_RESPONSE:
        return (
          <CuriosityCategoryLink
            slug={t("shortcuts.crisis_response.slug")}
            onClick={() => handleCuriosityItemClicked("crisis_response")}
          >
            <CuriosityImageAndText
              label={t("shortcuts.crisis_response.label")}
              image={crisisResponse}
              animatedImage={crisisResponseAnimated}
              animateAlwaysOnDesktop
            />
          </CuriosityCategoryLink>
        );
      case Curiosity.ADHD:
        return (
          <CuriosityCategoryLink
            slug={t("shortcuts.adhd.slug")}
            onClick={() => handleCuriosityItemClicked("adhd")}
          >
            <CuriosityImageAndText
              label={t("shortcuts.adhd.label")}
              image={adhd}
              animatedImage={adhdAnimated}
            />
          </CuriosityCategoryLink>
        );
      case Curiosity.LGBTQI:
        return (
          <CuriosityCategoryLink
            slug={t("shortcuts.lgbtqia.slug")}
            onClick={() => handleCuriosityItemClicked("lgbtqi")}
          >
            <CuriosityImageAndText
              label={t("shortcuts.lgbtqia.label")}
              image={lgbtqi}
              animatedImage={lgbtqiAnimated}
            />
          </CuriosityCategoryLink>
        );
      case Curiosity.EXPATS:
        return (
          <CuriosityCategoryLink
            slug={t("shortcuts.expats.slug")}
            onClick={() => handleCuriosityItemClicked("expats")}
          >
            <CuriosityImageAndText
              label={t("shortcuts.expats.label")}
              image={expats}
              animatedImage={expatsAnimated}
            />
          </CuriosityCategoryLink>
        );
      case Curiosity.CBT:
        return (
          <CuriosityCategoryLink
            slug={t("shortcuts.cbt.slug")}
            onClick={() => handleCuriosityItemClicked("cbt")}
          >
            <CuriosityImageAndText
              label={t("shortcuts.cbt.label")}
              image={cbtWoman}
              animatedImage={cbtWomanAnimated}
            />
          </CuriosityCategoryLink>
        );
      case Curiosity.JUNGIAN:
        return (
          <CuriosityCategoryLink
            slug={t("shortcuts.jungian.slug")}
            onClick={() => handleCuriosityItemClicked("jungian")}
          >
            <CuriosityImageAndText
              label={t("shortcuts.jungian.label")}
              image={jungian}
              animatedImage={jungianAnimated}
              animateAlwaysOnDesktop
            />
          </CuriosityCategoryLink>
        );
      case Curiosity.PSYCHOANALYSIS:
        return (
          <CuriosityCategoryLink
            slug={t("shortcuts.psychoanalysis.slug")}
            onClick={() => handleCuriosityItemClicked("psychoanalysis")}
          >
            <CuriosityImageAndText
              label={t("shortcuts.psychoanalysis.label")}
              image={psychoanalysis}
              animatedImage={psychoanalysisAnimated}
              animateAlwaysOnDesktop
            />
          </CuriosityCategoryLink>
        );
    }
  };
  return (
    <>
      {curiosities.map((curiosity) => (
        <React.Fragment key={curiosity}>
          {curiosityGenerator(curiosity)}
        </React.Fragment>
      ))}
    </>
  );
}

function CuriosityCategoryLink({
  slug,
  children,
  onClick,
}: {
  slug: string;
  children: React.ReactNode;
  onClick: () => void;
}) {
  return (
    <Link
      href={{
        pathname: "/find-help-with/[category_slug]",
        params: {
          category_slug: slug,
        },
      }}
      className={styles.curiosityLink}
      onClick={onClick}
    >
      {children}
    </Link>
  );
}

function handleCuriosityItemClicked(
  curiosity_item:
    | "coaching"
    | "get_matched"
    | "psychadelic_integration"
    | "couples_therapy"
    | "crisis_response"
    | "adhd"
    | "lgbtqi"
    | "expats"
    | "cbt"
    | "jungian"
    | "psychoanalysis",
) {
  directory.curiosityItemClicked({
    source_page: "landing_page",
    curiosity_item: curiosity_item,
  });
}

function CuriosityImageAndText({
  label,
  image,
  animatedImage,
  animateAlwaysOnDesktop,
}: {
  label: string;
  image: StaticImageData;
  animatedImage: StaticImageData;
  animateAlwaysOnDesktop?: boolean;
}) {
  const [isHovering, setIsHovering] = useState(false);
  const [allowAnimatedImages, setAllowAnimatedImages] = useState(false);

  useEffect(() => {
    // Update the state based on viewport width whenever the component mounts
    const handleResize = () => {
      // we only want to show gifs on desktop to keep the page light
      setAllowAnimatedImages(window.innerWidth > BREAKPOINTS.lg);
    };

    // Run once on mount
    handleResize();

    // Add event listener for subsequent resizes
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <Flex
      direction={"column"}
      className={styles.curiosity}
      gap={"3"}
      px={"3"}
      align={"center"}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={styles.curiosityImageContainer}>
        {!allowAnimatedImages && (
          <Image
            src={image}
            alt={""}
            className={`${styles.curiosityImage}`}
            width={88}
            height={88}
          />
        )}
        {allowAnimatedImages && (
          <>
            <Image
              src={(isHovering || animateAlwaysOnDesktop) ? animatedImage : image}
              alt={""}
              className={styles.curiosityImage}
              width={140}
              height={140}
            />
          </>
        )}
      </div>
      <TextV2
        textStyle={{
          initial: "Headline S",
          sm: "Headline S",
          md: "Headline M",
          lg: "Headline M",
        }}
        align={"center"}
        className={styles.curiosityLabel}
      >
        {label}
      </TextV2>
    </Flex>
  );
}

export { ClientLandingCuriosities };
